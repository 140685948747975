<template>
  <div>
    <div class="black pt-10 pb-10">
      <v-container>
        <h1 class="white--text text-center">BRAVIA Professional Display White Glove Service</h1>
      </v-container>
    </div>
    <div class="grey lighten-2 pt-10 pb-10">
      <v-container class="black--text">
        <p v-if="selectedLocale.languageType.name == 'fr'">
          Dans le cadre de nos offres de services complètes pour les produits professionnels de Sony, notre service
          Gants blancs pour écrans professionnels BRAVIA est conçu pour vous permettre de rester opérationnel dans les
          rares cas où un écran aurait besoin d'être réparé. En conjonction avec notre programme de remplacement avancé,
          notre nouveau service gants blancs pour les écrans professionnels BRAVIA fournira un technicien à votre
          emplacement pour retirer un écran défectueux et monter une unité de remplacement. Informez simplement notre
          groupe de service avec les informations de suivi de votre unité de remplacement, et le technicien planifiera
          un rendez-vous après l'arrivée de l'unité, et en fonction de votre horaire. Le technicien organisera également
          le renvoi de l'écran défectueux à Sony en votre nom. Notre service White Glove est disponible avec la garantie
          d'usine standard de 3 ans fournie avec l'écran, et est disponible pour les tailles d'écran suivantes avec un
          service de 2 jours ou de 3 à 5 jours après l'arrivée de l'unité de remplacement.
        </p>
        <p v-else>
          As part of our comprehensive service offerings for Sony's professional products, our BRAVIA Professional
          Display White Glove Service is designed to keep you up and running in the rare event that a display needs
          servicing. In conjunction with our Advanced Replacement Program, our new White Glove Service for BRAVIA
          Professional Displays will provide a technician to your location to remove a defective display and mount a
          replacement unit. Just notify our service group with the tracking information for your replacement unit, and
          the technician will schedule an appointment after the arrival of the unit, and based on your schedule. The
          technician will also arrange shipment of the defective display back to Sony on your behalf. Our White Glove
          Service is available with the standard 3-Year Factory Warranty provided with the display, and is available for
          the following display sizes with either 2 Day or 3-5 Day Service after the arrival of the replacement unit:
        </p>
        <ul>
          <li>
            32-55”
          </li>
          <li>65-85”</li>
          <li>100”</li>
        </ul>
        <p class="pt-2">
          Click
          <a href="//pro.sony/whiteglove/terms" target="_blank">here</a>
          for all Terms &amp; Conditions
        </p>
      </v-container>
    </div>
    <div class="white pt-10 pb-10">
      <v-container class="text-center" style="max-width: 1000px">
        <v-dialog v-model="examplesDialog" max-width="800" persistent>
          <v-card>
            <v-card-title>{{ $i18n.translate("Examples") }}</v-card-title>
            <v-card-text class="text-center"><img :src="dialogImage" alt="Sample Barcodes" width="600" /> </v-card-text>
            <v-card-actions
              ><v-spacer></v-spacer
              ><v-btn class="primary" @click="examplesDialog = false">{{
                $i18n.translate("Close")
              }}</v-btn></v-card-actions
            >
          </v-card>
        </v-dialog>
        <v-dialog v-model="uploadedFilesDialog" max-width="800" persistent>
          <v-card>
            <v-card-title>{{ $i18n.translate("Files") }}</v-card-title>
            <v-card-text>
              <v-container class="ma-0 pa-0">
                <v-row dense>
                  <v-col cols="1" dense></v-col>
                  <v-col cols="11" dense>
                    <ol>
                      <li v-for="(upload, i) in uploads" :key="i">
                        <a :href="upload.href" target="_blank">
                          {{ upload.originalFilename }}
                        </a>
                      </li>
                    </ol>
                  </v-col>
                </v-row>
                <v-row class="text-right" dense>
                  <v-col cols="12" dense>
                    <v-btn class="primary" @click="uploadedFilesDialog = false">Close</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>

        <ApiError :errors="errors" class="text-left"></ApiError>

        <span id="form-top"></span>

        <v-container v-if="!loading && !promotion.id">
          <h3 class="text-center pa-10">{{ $i18n.translate("Promotion is no longer available") }}</h3>
        </v-container>
        <v-container v-else-if="!claimSubmitted">
          <h3 class="mb-10">To register your display(s), just fill out the form below.</h3>

          <v-stepper v-model="step" vertical id="stepper">
            <v-stepper-step :editable="step > 1" step="1" style="text-align: left !important">{{
              $i18n.translate("Details")
            }}</v-stepper-step>

            <v-stepper-content step="1" id="step-1">
              <v-form ref="step1Form" @submit.prevent="onNextStep()" v-if="!loading" v-model="valid.step1">
                <v-container v-if="this.claimFields">
                  <v-row v-for="(claimFieldRow, i) in this.claimFieldsByRow" :key="i">
                    <template v-for="(claimField, j) in claimFieldRow">
                      <v-col cols="12" v-if="claimField.newCategory && claimField.category" :key="j + '-category'">
                        <v-subheader class="pl-0"
                          ><h3>{{ claimField.category }}</h3></v-subheader
                        >
                        <v-divider></v-divider>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        :md="claimField.cols"
                        :lg="claimField.cols"
                        :xl="claimField.cols"
                        :key="j"
                      >
                        <ClaimField
                          dense
                          :label="claimField.name"
                          :hint="claimField.description"
                          :promotion="promotion"
                          :claimField="claimField"
                          v-model="claimFieldValues[claimField.id]"
                          :error-messages="$error.getValidationError(errors, claimField.name)"
                          @input="$error.clearValidationError(errors, claimField.name)"
                          @otherSelected="updateSonyDealerFieldsVisibility($event)"
                          :publicField="true"
                        >
                        </ClaimField>
                      </v-col>
                    </template>
                  </v-row>
                </v-container>

                <div class="mb-5">
                  <v-btn color="primary" class="mx-1" @click="onNextStep()" :disabled="!valid.step1">
                    {{ $i18n.translate("Continue") }}
                  </v-btn>
                  <v-file-input
                    truncate-length="15"
                    v-model="fileToUpload"
                    @change="processUpload"
                    v-show="false"
                  ></v-file-input>
                </div>
              </v-form>
            </v-stepper-content>

            <v-stepper-step :editable="step > 2 || valid.step1" step="2" style="text-align: left !important"
              >Add Products</v-stepper-step
            >
            <v-stepper-content step="2" id="step-2">
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="text-left">
                    <v-form
                      ref="uploadForm"
                      @submit.prevent="onNextStep()"
                      v-if="!loading && supportingDocumentation"
                      v-model="valid.uploadForm1"
                    >
                      <v-row dense>
                        <v-col cols="12">
                          <h4>
                            Let us do the work for you!
                          </h4>
                          <p class="body-2">
                            Please upload a copy of your purchase invoice or receipt with the serial number of the
                            eligible model(s) included. We will then enter on your behalf and submit the claim. You will
                            receive email notification once completed.
                          </p>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12">
                          <UploadField
                            v-model="claimUploads"
                            :limitNumberFile="1"
                            :rules="[requireUpload]"
                            class="publicUploadField mt-n6"
                            @input="nextStepIfValidUpload"
                            :public="true"
                          >
                          </UploadField>
                        </v-col>
                        <!-- <mat-divider vertical style="height:100px"></mat-divider> -->
                      </v-row>
                    </v-form>
                  </v-col>
                  <v-col cols="12" sm="12" md="1" lg="1" xl="1" class="text-center">
                    <h4>OR</h4>
                  </v-col>

                  <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="text-left">
                    <h4>Manually Select Products</h4>
                    <p class="body-2">
                      Select products from the drop-down and begin entering serial numbers and purchase dates.
                    </p>
                    <div class="d-flex align-center">
                      <v-select
                        :items="promotion.promotionProducts"
                        v-model="selectedPromotionProducts"
                        :label="$i18n.translate('Select a Product')"
                        item-value="id"
                        :item-text="item => `${item.product.productKey}`"
                        multiple
                        return-object
                        class="required promotion_products_select mr-5 promotionProductSelect"
                        :hint="$i18n.translate('You must select at least 1 product for this claim')"
                        auto
                        :menu-props="{ bottom: true, offsetY: true, zIndex: '9999' }"
                        ref="promotionProductsSelect"
                        :isMenuActive="true"
                      ></v-select>
                      <v-btn
                        color="primary"
                        small
                        @click="
                          onAddProduct();
                          $refs['promotionProductsSelect'].isMenuActive = false;
                        "
                        >{{ $i18n.translate("Add") }}</v-btn
                      >
                    </div>
                  </v-col>
                  <v-col cols="12" sm="12" md="1" lg="1" xl="1" class="text-center">
                    <h4>OR</h4>
                  </v-col>
                  <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="text-left">
                    <h4>Upload Products with CSV File</h4>
                    <p class="body-2">
                      Use a sample CSV (&quot;Comma Separated Value&quot;) template and upload products with their
                      serial numbers and purchase dates.
                    </p>
                    <v-btn small color="primary" @click="showUploadDialog = true">Begin Uploading Products</v-btn>
                    <v-dialog v-model="showUploadDialog" max-width="600">
                      <v-card>
                        <v-card-title>Upload Products using a CSV File</v-card-title>
                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col>
                                <ol>
                                  <li style="line-height: 40px;">
                                    Download
                                    <v-btn class="ml-2" small color="primary" @click="downloadTemplate"
                                      ><v-icon>mdi-download</v-icon> Sample CSV File
                                    </v-btn>
                                    with formatted headers.
                                  </li>
                                  <li style="line-height: 40px;">
                                    Remove sample data from the template (Keep row 1 headers).
                                  </li>
                                  <li style="line-height: 40px;">All columns on the template are required.</li>
                                  <li style="line-height: 40px;">Do not change the order of the columns.</li>
                                  <li style="line-height: 40px;">
                                    Upload your template file by clicking this button:
                                    <v-btn
                                      class="ml-2"
                                      small
                                      color="primary"
                                      @click="startUploadProcess"
                                      :loading="loading"
                                      ><v-icon>mdi-upload</v-icon> Upload CSV</v-btn
                                    >
                                  </li>
                                </ol>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                        <v-card-actions
                          ><v-spacer></v-spacer><v-btn @click="showUploadDialog = false">Cancel</v-btn></v-card-actions
                        >
                      </v-card>
                    </v-dialog>
                  </v-col>
                </v-row>
                <v-row>
                  <v-divider />
                </v-row>
                <v-form ref="step2Form" @submit.prevent="onNextStep()" v-if="!loading" v-model="valid.step2">
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        :headers="claimProductHeaders"
                        :items="claimProducts"
                        disable-pagination
                        hide-default-footer
                        no-data-text="No Products Selected"
                        no-results-text="No Products Selected"
                      >
                        <template v-slot:item.serialNumber="{ item }">
                          <v-container>
                            <v-row>
                              <v-col cols="12">
                                <v-text-field
                                  :label="$i18n.translate('Serial Number')"
                                  v-model="item.serialNumber"
                                  @keydown="keyHandler($event)"
                                  :rules="
                                    item.promotionProduct.enableSerialNumbers
                                      ? [requireSerialNumber(item), checkDuplicatedSerialNumber(item)]
                                      : []
                                  "
                                  @blur="formatSerialNumber(item)"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-container>
                        </template>

                        <template v-slot:item.sku="{ item }">
                          <v-select
                            :items="productToSkuMapping[item.promotionProduct.product.productKey]"
                            v-model="item.sku"
                            :rules="rules.sku"
                            clearable
                          >
                            <template slot="item" slot-scope="data">
                              {{ data.item }}({{
                                data.item.startsWith("WG5")
                                  ? "Five-Day Service"
                                  : data.item.startsWith("WG2")
                                  ? "Two-Day Service"
                                  : ""
                              }})
                            </template>
                          </v-select>
                        </template>

                        <template v-slot:item.purchaseDate="{ item }">
                          <DateTimePickerField
                            v-model="item.purchaseDate"
                            :rules="getPurchaseDateRules()"
                            format="MM/DD/YYYY"
                            defaultTime="12:00"
                            onlyDate
                          ></DateTimePickerField>
                        </template>
                        <template v-slot:item.actions="{ item }">
                          <div style="white-space: nowrap">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon class="mx-1" v-bind="attrs" v-on="on" @click="onDeleteProduct(item)"
                                  >mdi-delete</v-icon
                                >
                              </template>
                              <span>{{ $i18n.translate("Delete Product") }}</span>
                            </v-tooltip>

                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon class="mx-1" v-bind="attrs" v-on="on" @click="onDuplicateProduct(item)"
                                  >mdi-content-duplicate</v-icon
                                >
                              </template>
                              <span>{{ $i18n.translate("Duplicate Product") }}</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  class="mx-1"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="$refs['promotionProductsSelect'].isMenuActive = true"
                                  >mdi-plus-circle-outline</v-icon
                                >
                              </template>
                              <span>{{ $i18n.translate("Add new product") }}</span>
                            </v-tooltip>
                          </div>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>

              <div class="mb-5">
                <v-btn
                  color="primary"
                  class="mx-1"
                  @click="onNextStep()"
                  :disabled="!validClaimProducts && !valid.step3"
                >
                  {{ $i18n.translate("Continue") }}
                </v-btn>
                <v-btn class="mx-1" @click="onPrevStep" text>{{ $i18n.translate("Back") }}</v-btn>
              </div>
            </v-stepper-content>

            <v-stepper-step
              :editable="step > 3 || (valid.step1 && valid.step2 && claimProducts.length > 0)"
              step="3"
              v-if="supportingDocumentation"
            >
              {{ $i18n.translate("Supporting Documentation") }}
            </v-stepper-step>

            <v-stepper-content step="3" v-if="supportingDocumentation">
              <v-form ref="step3Form" @submit.prevent="onNextStep" v-if="!isBusy" v-model="valid.step3">
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <UploadField
                        v-model="claimUploads"
                        :rules="[requireUpload]"
                        :limitNumberFile="1"
                        :public="true"
                      ></UploadField>
                    </v-col>
                  </v-row>
                </v-container>
                <v-btn color="primary" @click="onNextStep(4)" :disabled="!valid.step3">
                  {{ $i18n.translate("Continue") }}
                </v-btn>
                <v-btn @click="onCancel" text>{{ $i18n.translate("Cancel") }}</v-btn>
              </v-form>
            </v-stepper-content>

            <v-stepper-step
              :editable="step > 4 || (valid.step1 && valid.step2 && valid.step3)"
              :step="supportingDocumentation ? 4 : 3"
              style="text-align: left !important"
            >
              {{ $i18n.translate("Review Claim Details") }}
            </v-stepper-step>
            <v-stepper-content id="step-3" :step="4">
              <v-form @submit.prevent="onSubmit" v-model="valid.step4">
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-card>
                        <v-card-title primary-title>
                          {{ $i18n.translate("Review Claim Details") }}
                        </v-card-title>
                        <v-card-text>
                          <v-simple-table dense>
                            <template v-slot:default>
                              <tbody>
                                <tr v-if="isAdminOrPrincipalOwner">
                                  <th>
                                    {{ $i18n.translate("Participant selected to claim on behalf") }}
                                  </th>
                                  <td>
                                    {{ participantSelectedToClaimOnBehalf.fullName }}
                                  </td>
                                </tr>

                                <tr v-for="(claimField, i) in claimFields" :key="i + '-values'">
                                  <th width="30%" class="text-right">{{ claimField.name }}</th>
                                  <td width="70%" class="text-left">
                                    {{
                                      claimFieldValues[claimField.id] && claimFieldValues[claimField.id].name
                                        ? claimFieldValues[claimField.id].name
                                        : claimFieldValues[claimField.id]
                                    }}
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table></v-card-text
                        >
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row v-if="claimProducts && claimProducts.length > 0">
                    <v-col cols="12">
                      <v-card>
                        <v-card-title primary-title>
                          {{ $i18n.translate("Products") }}
                        </v-card-title>
                        <v-card-text>
                          <v-simple-table dense>
                            <template v-slot:default>
                              <tbody>
                                <tr v-for="(claimProduct, i) in claimProducts" :key="i + '-products'">
                                  <th width="30%" class="text-right">
                                    {{ claimProduct.promotionProduct.product.productKey }}
                                  </th>
                                  <td width="70%" class="text-left">
                                    <span v-if="claimProduct.promotionProduct.enableSerialNumbers">
                                      {{ $i18n.translate("Serial Number") }}: {{ claimProduct.serialNumber }} | SKU:
                                      {{ claimProduct.sku }}
                                    </span>
                                    <span v-else> {{ $i18n.translate("Quantity") }}: {{ claimProduct.quantity }}</span>
                                  </td>
                                </tr>
                                <tr>
                                  <th width="30%" class="text-right">
                                    Total Products Claimed
                                  </th>
                                  <td width="70%" class="text-left">
                                    <span>{{ totalProductsClaimed }}</span>
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table></v-card-text
                        >
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row v-if="supportingDocumentation">
                    <v-col cols="12">
                      <v-card>
                        <v-card-title primary-title>
                          {{ $i18n.translate("Supporting Documentation") }}
                        </v-card-title>
                        <v-card-text
                          ><v-simple-table dense>
                            <template v-slot:default>
                              <tbody>
                                <tr v-for="(claimUpload, i) in claimUploads.existing" :key="i + '-uploads'">
                                  <th width="30%" class="text-right">File {{ i + 1 }}</th>
                                  <td width="70%" class="text-left">
                                    <a :href="claimUpload.href" target="_blank">{{ claimUpload.originalFilename }}</a>
                                    ({{ claimUpload.contentType }})
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table></v-card-text
                        >
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-card>
                        <v-card-title primary-title>
                          {{ $i18n.translate("Sony email communications") }}
                        </v-card-title>
                        <v-card-text v-if="selectedLocale.languageType.name == 'fr'">
                          <p>
                            <v-checkbox
                              label="Je souhaite recevoir des communications par e-mail de Sony Imaging Products &amp; Solutions - Americas"
                              v-model="optIn"
                            ></v-checkbox>

                            En cliquant sur 'Soumettre', je confirme que j'accepte le Sony Electronics
                            <a href="https://products.sel.sony.com/SEL/legal/privacy.html" target="_blank"
                              >Politique de confidentialité </a
                            >et certifie que je suis un résident des États-Unis. Vous pouvez
                            <a href="https://m.info.pro.sony.com/webApp/SonyPSAUnsubscribe_01" target="_blank"
                              >se désinscrire</a
                            >
                            à tout moment.
                          </p>
                        </v-card-text>
                        <v-card-text v-else>
                          <p>
                            <v-checkbox
                              label="I'd like to receive Email communications from Sony Imaging Products &amp; Solutions - Americas."
                              v-model="optIn"
                            ></v-checkbox>

                            By clicking 'Submit', I confirm that I agree to the Sony Electronics
                            <a href="https://products.sel.sony.com/SEL/legal/privacy.html" target="_blank"
                              >Privacy Policy </a
                            >and certify that I am a U.S. resident. You may
                            <a href="https://m.info.pro.sony.com/webApp/SonyPSAUnsubscribe_01" target="_blank"
                              >Unsubscribe</a
                            >
                            at any time.
                          </p>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>

                <div class="mb-5">
                  <v-btn color="primary" class="mx-1" :disabled="!valid.step4" :loading="loading" @click="onSubmit()">
                    {{ $i18n.translate("Submit") }} </v-btn
                  ><br />
                  <GoogleReCaptcha></GoogleReCaptcha>
                  <v-btn class="mx-1" @click="onPrevStep" v-if="!loading" text>{{ $i18n.translate("Back") }}</v-btn>
                </div>
              </v-form>
            </v-stepper-content>
          </v-stepper>
        </v-container>
        <v-container v-else class="text-center">
          <v-icon x-large color="green" id="claim-submitted">mdi-check-circle-outline</v-icon>
          <h3 class="ma-5">{{ $i18n.translate("Registration Submitted Successfully") }}</h3>
          <br />
          <p class="mb-0">{{ $i18n.translate("Registration Number") }}</p>
          <h3>{{ claimNumber }}</h3>
          <p class="text-caption mt-10" v-if="selectedLocale.languageType.name == 'fr'">
            Avez-vous des questions? N'hésitez pas à nous envoyer un e-mail à<a
              :href="'mailto:' + selectedProgram.programEmail"
              >{{ selectedProgram.programEmail }}</a
            >, ou appelez simplement {{ selectedProgram.programPhone }}. Notre équipe de service à la clientèle est
            disponible entre 8h30 et 17h00 (ct) du lundi au vendredi pour vous aider.
          </p>
          <p class="text-caption mt-10" v-else>
            Have any questions? Don't hesitate to email us at
            <a :href="'mailto:' + selectedProgram.programEmail">{{ selectedProgram.programEmail }}</a
            >, or simply call {{ selectedProgram.programPhone }}. Our customer care team is available between 8:30am and
            5:00pm (ct) Monday through Friday to assist.
          </p>
        </v-container>
        <v-dialog v-model="showUploadProcessingResultDialog" max-width="600">
          <v-card>
            <v-card-title>Product Upload Completed</v-card-title>
            <v-card-text
              ><br />
              <v-row justify="center">
                <v-col cols="10">
                  <v-simple-table dense>
                    <template v-slot:default>
                      <tbody>
                        <tr>
                          <th width="40%" class="text-right">Products Imported</th>
                          <td width="60%">
                            {{ processingResult.processed }}
                          </td>
                        </tr>
                        <tr>
                          <th width="40%" class="text-right">Products Failed</th>
                          <td width="60%">
                            {{ processingResult.failed }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
              <br />
              <v-row v-if="processingResult && processingResult.errors && processingResult.errors.length > 0">
                <v-col cols="12" align-self="center">
                  <v-data-table
                    disable-pagination
                    dense
                    :headers="[{ text: 'Errors', value: 'description' }]"
                    :items="
                      processingResult.errors
                        ? processingResult.errors.map(error => {
                            return { description: error };
                          })
                        : null
                    "
                  ></v-data-table>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions
              ><v-spacer></v-spacer
              ><v-btn class="primary" @click="showUploadProcessingResultDialog = false">Continue</v-btn></v-card-actions
            >
          </v-card>
        </v-dialog>
      </v-container>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import Vue from "vue";
import { mapGetters } from "vuex";
import ApiError from "../../gapp-components/components/display/ApiError.vue";
import GoogleReCaptcha from "../../gapp-components/components/display/GoogleReCaptcha.vue";
import ClaimField from "../../gapp-components/components/fields/ClaimField.vue";
import UploadField from "../../gapp-components/components/fields/UploadField.vue";

export default {
  components: { ApiError, ClaimField, UploadField, GoogleReCaptcha },
  name: "whiteglove",
  metaInfo: {
    title: "White Glove"
  },
  data: () => ({
    env: null,
    fileToUpload: null,
    step: 1,
    valid: {
      step1: false,
      step2: false,
      step3: false,
      step4: false,
      uploadForm1: false
    },
    loading: false,
    isNew: false,
    isEditing: true,
    isBusy: false,
    promotionId: 0,
    errors: {},
    serialNumberRegex: "",

    promotion: {},
    claimFields: [],
    claimFieldsByRow: [],
    claimFieldValues: {},
    defaultTermsAndConditions: [],
    selectedPromotionProducts: [],
    claimProducts: [],
    claimProductHeaders: [
      {
        value: "promotionProduct.product.productKey",
        text: "Model",
        align: "left"
      },
      {
        value: "serialNumber",
        text: "Serial Number",
        sortable: false
      },
      {
        value: "sku",
        text: "SKU",
        sortable: false
      },
      {
        value: "purchaseDate",
        text: "Date of Purchase",
        sortable: false
      },
      {
        value: "actions",
        text: "",
        align: "center",
        sortable: false
      }
    ],

    claimSubmitted: false,

    claimStage: null,

    claimUploads: {
      existing: [],
      deleted: []
    },

    rules: {
      required: [v => !!v || "Field is required"],
      participant: [v => !!v || "Participant is required"],
      quantity: [v => !!v || "Quantity is required"],
      terms: [v => !!v || "Terms is required"],
      sku: [v => !!v || "Sku is required"]
    },

    form: {},
    showUploadProcessingResultDialog: false,
    processingResult: {},
    uploads: {},
    uploadedFilesDialog: false,

    participantSelectedToClaimOnBehalf: null,
    claimNumber: "",
    q1: false,
    zeroSales: false,
    zeroSalesItems: [],
    selectedZeroSalesPeriod: null,
    formattedZeroSalesPeriod: null,
    saveEndUserForLater: false,
    selectedEndUser: null,

    availableAwardVehicles: [],

    participantSkipClaimDocumentation: false,
    promotionSkipClaimDocumentation: false,
    promotionTypeDocumentationRequired: false,
    supportingDocumentation: true,
    terms: false,
    examplesDialog: false,
    dialogImage: require("@/assets/sample-barcodes.png"),
    optIn: true,
    showUploadDialog: false,
    unsubmittedClaimStage: null,

    productToSkuMapping: {
      FW100BZ40J: ["WG2Y100", "WG5Y100"],
      FW32BZ30J: ["WG2Y3255", "WG5Y3255"],
      FW43BZ30J: ["WG2Y3255", "WG5Y3255"],
      FW43BZ35J: ["WG2Y3255", "WG5Y3255"],
      FW50BZ30J: ["WG2Y3255", "WG5Y3255"],
      FW50BZ35J: ["WG2Y3255", "WG5Y3255"],
      FW55BZ30J: ["WG2Y3255", "WG5Y3255"],
      FW55BZ40H: ["WG2Y3255", "WG5Y3255"],
      FW65BZ30J: ["WG2Y6585", "WG5Y6585"],
      FW65BZ40H: ["WG2Y6585", "WG5Y6585"],
      FW75BZ30J: ["WG2Y6585", "WG5Y6585"],
      FW75BZ40H: ["WG2Y6585", "WG5Y6585"],
      FW85BZ40H: ["WG2Y6585", "WG5Y6585"],
      FWD55X75K: ["WG2Y3255", "WG5Y3255"],
      FWD65X75K: ["WG2Y6585", "WG5Y6585"],
      FWD75X80K: ["WG2Y6585", "WG5Y6585"],
      FWD85X80K: ["WG2Y6585", "WG5Y6585"]
    }
  }),
  created() {
    this.env = process.env;
    this.promotionId = parseInt(this.$route.params.id);
    if (this.$route.params.id == 0) {
      this.isNew = true;
    }
    this.participantSkipClaimDocumentation = false;
    this.promotionSkipClaimDocumentation = this.promotion.skipClaimDocumentation;
    this.fetchData();
    this.loadZeroSalesMonths();
  },
  watch: {
    claimProducts: {
      handler() {
        this.$refs.step2Form.validate();
      },
      deep: true
    },
    claimUploads: {
      handler() {
        this.$refs.uploadForm.validate();
        this.$refs.step2Form.validate();
      },
      deep: true
    },
    selectedZeroSalesPeriod() {
      this.formattedZeroSalesPeriod = moment
        .tz(this.selectedZeroSalesPeriod, this.selectedClient.timezone)
        .format("MMMM YYYY");
    },
    selectedLocale() {
      this.fetchData();
    }
  },
  computed: {
    ...mapGetters(["selectedParticipant", "selectedProgram", "selectedClient", "selectedLocale", "selectedCountry"]),
    isAdminOrPrincipalOwner() {
      //   return ["500", "510", "600", "610", "700", "710", "800", "810"].includes(
      //     this.selectedParticipant.participantType.participantTypeKey
      //   );
      return false;
    },
    validClaimProducts() {
      if (!this.claimProducts || this.claimProducts.length == 0) return false;
      return this.valid.step2;
    },
    totalProductsClaimed() {
      let amount = 0;
      for (let cp of this.claimProducts) {
        if (cp.quantity) {
          amount += eval(cp.quantity);
        } else {
          amount += 1;
        }
      }
      return amount;
    },
    termsAndConditions() {
      let selectedLocaleName = this.selectedLocale.languageType.name;
      if (this.promotion && this.promotion.promotionType && this.promotion.promotionType.termsAndConditions) {
        let promotionTypeTermsAndConditions = this.promotion.promotionType.termsAndConditions.filter(
          tac => tac.language.name == selectedLocaleName
        );
        if (promotionTypeTermsAndConditions.length > 0) {
          return promotionTypeTermsAndConditions[0];
        }
      }
      if (this.defaultTermsAndConditions) {
        let programTermsAndConditions = this.defaultTermsAndConditions.filter(
          tac => tac.language.name == selectedLocaleName
        );
        if (programTermsAndConditions.length > 0) {
          return programTermsAndConditions[0];
        }
      }

      return {
        description: "Terms And Conditions error. Not specified."
      };
    }
  },
  methods: {
    nextStepIfValidUpload() {
      this.$refs.uploadForm.validate();
      this.$nextTick(() => {
        if (this.validClaimProducts || this.valid.uploadForm1) {
          this.onNextStep(4);
        }
      });
    },
    getPurchaseDateRules() {
      return [
        v => !!v || "Date of purchase is required",
        v => {
          if (v && moment(v).isAfter(moment())) {
            return "Date of purchase cannot be in the future";
          }
          return true;
        },
        v => {
          if (v && moment.tz(v, "MM/DD/YYYY", "America/New_York").isBefore(moment(this.promotion.effectiveDate))) {
            return "Date of purchase cannot be before the start of the promotion";
          }
          return true;
        }
      ];
    },
    downloadTemplate() {
      let csvContent =
        "data:text/csv;charset=utf-8," + "Product Model,Serial Number,SKU,Date of Purchase (MM-DD-YYYY or M-DD-YYYY)";
      this.promotion.promotionProducts.forEach(promotionProduct => {
        csvContent +=
          "\r\n" + promotionProduct.product.productKey + ",123456789,SKU123," + moment().format("MM-DD-YYYY");
      });

      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "ClaimProductsTemplate.csv");
      document.body.appendChild(link); // Required for FF

      link.click();
    },
    startUploadProcess() {
      var input = document.createElement("input");
      input.type = "file";
      input.accept = ".csv";
      input.click();

      input.onchange = () => {
        const reader = new FileReader();
        reader.onload = e => this.processUpload(e);
        for (let file of input.files) {
          reader.readAsText(file);
        }
        reader.onloadend = () => {
          // validate form after load
          this.$refs.step2Form.validate();
        };
      };
    },
    processUpload(event) {
      let fileContent = event.target.result;
      let lines = fileContent.split("\r\n");
      this.processingResult = {
        processed: 0,
        failed: 0,
        errors: []
      };
      let currentLineNumber = 0;
      for (let line of lines) {
        if (currentLineNumber == 0) {
          currentLineNumber++;
          continue;
        }
        if (!line || line.trim() == "") {
          continue;
        }
        let validationResult = this.validateLine(line, currentLineNumber + 1);
        if (validationResult.isValid()) {
          let lineElements = line.split(",");

          let purchaseDate = moment(lineElements[3]).format("YYYY-MM-DD");
          purchaseDate = this.$util.parseDateClient(purchaseDate, "YYYY-MM-DD", this.selectedClient);
          purchaseDate = purchaseDate.format("YYYY-MM-DD") + " 12:00:00";

          let newClaimProduct = {
            promotionProduct: this.promotion.promotionProducts.find(pp => {
              return pp.product.productKey.trim() == lineElements[0].trim();
            }),
            serialNumber: lineElements[1],
            sku: lineElements[2],
            purchaseDate: purchaseDate
          };
          this.claimProducts.push(newClaimProduct);
          this.processingResult.processed++;
        } else {
          this.processingResult.errors.push(...validationResult.getErrors());
          console.log(this.processingResult.failed);
          this.processingResult.failed++;
        }
        currentLineNumber++;
      }
      this.showUploadDialog = false;
      this.showUploadProcessingResultDialog = true;
    },

    validateLine(line, lineNumber) {
      let validationResult = {
        errors: [],
        isValid() {
          return this.errors.length == 0;
        },
        getErrors() {
          return this.errors;
        },
        addError(error) {
          this.errors.push("Line " + lineNumber + ": " + error);
        }
      };

      let lineElements = line.split(",");

      if (!line || line.trim() == "") {
        validationResult.addError("Empty line.");
        return validationResult;
      }

      if (lineElements.length != 4) {
        validationResult.addError("Invalid amount of elements.");
        return validationResult;
      }

      if (lineElements[0].trim() == "") {
        validationResult.addError("Product model can't be empty.");
      } else if (
        this.promotion.promotionProducts.find(pp => {
          return pp.product.productKey.trim() == lineElements[0].trim();
        }) == null
      ) {
        validationResult.addError(lineElements[0].trim() + " is not a valid product model.");
      } else if (
        !this.promotion.promotionProducts.find(pp => {
          return pp.product.productKey.trim() == lineElements[0].trim();
        }).enableSerialNumbers
      ) {
        validationResult.addError("Product model doesn't allow serial numbers.");
      }

      if (lineElements[1].trim() == "") {
        validationResult.addError("Serial number can't be empty.");
      }

      if (lineElements[2].trim() == "") {
        validationResult.addError("SKU can't be empty.");
      } else {
        let skuList = this.productToSkuMapping[lineElements[0].trim()];
        if (!skuList.includes(lineElements[2].trim())) {
          validationResult.addError("SKU is not valid.");
        }
      }

      if (lineElements[3].trim() == "") {
        validationResult.addError("Date of purchase can't be empty.");
      } else if (!moment(lineElements[3].trim()).isValid()) {
        validationResult.addError("Invalid format for date of purchase. YYYY-MM-DD format is required");
      }
      return validationResult;
    },
    onNextStep(specificStep) {
      this.step = parseInt(this.step);

      if (!specificStep) {
        specificStep = this.step + 1;
      } else {
        specificStep = parseInt(specificStep);
      }

      switch (this.step) {
        case 1:
          if (!this.valid.step1) {
            this.$refs.step1Form.validate();
          } else {
            this.step = specificStep;
          }
          break;
        case 2:
          if (!this.valid.step1 || !this.valid.step2) {
            this.$refs.step1Form.validate();
            this.$refs.step2Form.validate();
            this.$refs.uploadForm.validate();
          } else {
            this.step = specificStep;
          }
          break;
        case 3:
          if (!this.valid.step1 || !this.validClaimProducts) {
            this.$refs.step1Form.validate();
            this.$refs.step2Form.validate();
          } else {
            this.step = specificStep;
          }
          break;
        case 4:
          if (
            !this.valid.step1 ||
            (!this.validClaimProducts && !this.valid.uploadForm1) ||
            (!this.valid.step3 && this.validClaimProducts)
          ) {
            this.$refs.step1Form.validate();
            this.$refs.step2Form.validate();
            this.$refs.step3Form.validate();
          } else {
            this.step = specificStep;
          }
          break;
      }
      this.$vuetify.goTo("#form-top");
    },
    onPrevStep() {
      this.step--;
      if (this.step < 1) {
        this.step = 1;
      }
    },
    checkDuplicatedSerialNumber(item) {
      let count = 0;
      this.claimProducts.forEach(element => {
        if (
          element.serialNumber &&
          element.promotionProduct.product.id == item.promotionProduct.product.id &&
          element.serialNumber == item.serialNumber
        ) {
          count++;
        }
      });
      if (count > 1) {
        return "Serial number duplicated";
      }
      return true;
    },
    requireSerialNumber(item) {
      if (item.promotionProduct.enableSerialNumbers) {
        if (item.serialNumber) {
          if (item.promotionProduct.product.serialNumberRegex) {
            let regex = RegExp("^" + item.promotionProduct.product.serialNumberRegex);
            if (regex.test(item.serialNumber)) {
              return true;
            } else {
              return "Invalid Serial Number";
            }
          } else {
            return true;
          }
        } else {
          return "Serial Number required";
        }
      } else {
        return false;
      }
    },
    requireUpload() {
      if (this.claimUploads.existing.length > 0 && !this.promotionSkipClaimDocumentation) {
        return true;
      } else {
        return "At least 1 upload is required";
      }
    },
    onAddProduct() {
      let promotionProducts = this.claimProducts;
      this.selectedPromotionProducts.some(promotionProduct => {
        let found = promotionProducts.findIndex(
          needle => needle.promotionProduct.product.id == promotionProduct.product.id
        );
        if (found == -1) {
          promotionProducts = promotionProducts.concat([{ promotionProduct: promotionProduct }]);
        } else {
          if (promotionProduct.enableSerialNumbers) {
            promotionProducts = promotionProducts.concat([{ promotionProduct: promotionProduct }]);
          }
        }
      });
      this.claimProducts = promotionProducts;
      this.selectedPromotionProducts = [];
    },
    onDeleteProduct(claimProduct) {
      const index = this.claimProducts.indexOf(claimProduct);
      this.claimProducts.splice(index, 1);
    },
    onDuplicateProduct(claimProduct) {
      let claimProductCopy = { ...claimProduct };
      this.claimProducts = this.claimProducts.concat(claimProductCopy);
    },
    onSubmit() {
      this.loading = true;
      this.errors = {};

      let postForm = {};
      postForm.promotionKey = this.promotion.promotionKey;
      // postForm.claimStage - allow this to be determined
      postForm.participantTypeKey = "950";
      postForm.participantStatus = { name: "ENR" };
      postForm.zeroSales = false;
      postForm.browserSource = window.location.href;
      postForm.browserReferer = document.referrer || this.getReferrerFromQueryString();
      postForm.uploads = this.claimUploads.existing;
      postForm.claimProducts = this.claimProducts;
      postForm.claimProducts.forEach(cp => {
        if (cp.purchaseDate) {
          cp.purchaseDate = moment(cp.purchaseDate).format("YYYY-MM-DDTHH:mm:ss.SSSZ");
        }
      });
      postForm.claimFieldValues = this.getClaimFieldValues();
      postForm.reuseParticipant = true;
      postForm.optIn = this.optIn;
      postForm.emailTemplateParticipantForm = {
        emailTemplateKey: "WHITE_GLOVE_CLAIM_SUBMISSION"
      };

      /**
        If there are products, use default claim stage which routes to the first "audit" claim stage.

        If there are no products, then pass the "unsubmitted" claim stage as the first stage allowing
        Gapp to update the claim based on the uploaded invoice.
      **/
      if (this.claimProducts && this.claimProducts.length > 0) {
        postForm.claimStage = null;
      } else {
        postForm.claimStage =
          this.unsubmittedClaimStage && this.unsubmittedClaimStage.id ? { id: this.unsubmittedClaimStage.id } : null;
      }

      this.$api
        .postWithCaptcha("/api/claims/submit/public", postForm, null, "whiteGlove")
        .then(({ data }) => {
          this.form = data;
          this.claimNumber = data.claimKey;
          this.claimSubmitted = true;
        })
        .catch(error => {
          this.claimSubmitted = false;
          this.errors = this.$api.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.loading = false;
          this.$vuetify.goTo(0);
        });
    },
    getReferrerFromQueryString() {
      var queryString = window.location.search.substring(1);
      var params = new URLSearchParams(queryString);
      if (params.has("referrer")) {
        return params.get("referrer");
      } else {
        return null;
      }
    },
    getClaimFieldValues() {
      const claimFieldsClone = this.claimFields.slice();
      let cfValues = [];
      claimFieldsClone.map(claimField => {
        let cfValue = {
          claimField: { id: claimField.id },
          value:
            this.claimFieldValues[claimField.id] && this.claimFieldValues[claimField.id].name
              ? this.claimFieldValues[claimField.id].name.trim()
              : this.claimFieldValues[claimField.id]
              ? this.claimFieldValues[claimField.id].trim()
              : this.claimFieldValues[claimField.id]
        };
        cfValues.push(cfValue);
      });
      return cfValues;
    },
    fetchData() {
      this.loading = true;

      let filters = {};
      filters.country = {
        name: this.selectedLocale.countryType.name
      };
      filters.promotionTypeKey = this.env.VUE_APP_PROGRAM_EUR_PROMOTION_TYPE_KEY_WHITEGLOVE;
      this.$api
        .post("/api/promotions/search/eligible/public?size=100", filters, null, "whiteGlovePromotionsSearch")
        .then(({ data }) => {
          Vue.set(this, "promotion", data.content[0].promotion);
          this.defaultTermsAndConditions = data.content[0].programTermsAndConditions;
          this.supportingDocumentation = !this.promotion.skipClaimDocumentation;

          Vue.set(
            this.promotion,
            "promotionProducts",
            data.content[0].promotionProducts.filter(item => item.effective == true)
          );
          this.claimFields = data.content[0].claimFields.sort((a, b) => a.rankOrder - b.rankOrder);

          this.claimFieldsByRow = [];
          let rowCount = -1;
          let colCount = 0;

          this.claimFields.forEach((claimField, i) => {
            if (!claimField.cols || claimField.cols < 1 || claimField.cols > 12) {
              claimField.cols = 12;
            }
            if (i == 0 || this.claimFields[i].category != this.claimFields[i - 1].category) {
              claimField.newCategory = true;
            } else {
              claimField.newCategory = false;
            }
            if (claimField.newCategory || colCount + claimField.cols > 12) {
              rowCount++;
              colCount = claimField.cols;
            } else {
              colCount += claimField.cols;
            }
            if (!this.claimFieldsByRow[rowCount]) {
              this.claimFieldsByRow[rowCount] = [];
            }
            this.claimFieldsByRow[rowCount].push(claimField);
            if (claimField.defaultValue && claimField.defaultValue.length > 0) {
              this.claimFieldValues[claimField.id] = claimField.defaultValue;
            }
          });
          if (this.selectedParticipant) {
            if (this.selectedParticipant.optIn == undefined) {
              this.optIn = true;
            } else {
              this.optIn = this.selectedParticipant.optIn;
            }
          }

          if (data.content[0].uploads && data.content[0].uploads.length > 0) {
            data.content[0].uploads.forEach(upload => {
              if (upload.contentType.startsWith("image")) {
                Vue.set(this.promotion, "banner", upload);
              } else if (upload.contentType.startsWith("application")) {
                Vue.set(this.promotion, "download", upload);
              }
            });
          }

          this.$api
            .get("/api/claimStages/byClaimTypeId/" + this.promotion.claimType.id + "/public?sort=rankOrder,ASC&size=1")
            .then(({ data }) => {
              this.unsubmittedClaimStage = data && data.content && data.content.length > 0 ? data.content[0] : null;
            });
        })
        .catch(e => {
          Vue.set(this, "promotion", {});
          console.log("Unable to load promotion: " + e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateSonyDealerFieldsVisibility(v) {
      let selectedItem = v.selectedItem;
      let other = v.other;
      for (let claimField of this.claimFields) {
        if (claimField.name && claimField.name.startsWith("Sony Dealer") && claimField.name.trim() != "Sony Dealer") {
          claimField.hidden = !v.other;
          if (!other) {
            if (selectedItem) {
              switch (claimField.name) {
                case "Sony Dealer Name":
                  this.claimFieldValues[claimField.id] = selectedItem.name;
                  break;
                case "Sony Dealer Address 1":
                  this.claimFieldValues[claimField.id] = selectedItem.address1;
                  break;
                case "Sony Dealer Address 2":
                  this.claimFieldValues[claimField.id] = selectedItem.address2;
                  break;
                case "Sony Dealer City":
                  this.claimFieldValues[claimField.id] = selectedItem.city;
                  break;
                case "Sony Dealer State":
                  this.claimFieldValues[claimField.id] = selectedItem.region;
                  break;
                case "Sony Dealer Country":
                  this.claimFieldValues[claimField.id] = selectedItem.country;
                  break;
                case "Sony Dealer Postal Code":
                  this.claimFieldValues[claimField.id] = selectedItem.postalCode;
                  break;
              }
            }
          }
          if (other || !selectedItem) {
            this.claimFieldValues[claimField.id] = null;
          }
        }
      }
      this.$forceUpdate();
    },
    getUploads(promotion) {
      return this.$api
        .get("/api/promotions/" + promotion.id + "/uploads")
        .then(({ data }) => {
          Vue.set(promotion, "uploads", data._embedded.uploads);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onCancel() {
      this.$router.push({ name: "eurList" });
    },
    showUploadedFilesDialog() {
      this.$api
        .get("/api/promotions/" + this.$route.params.id + "/uploads/")
        .then(({ data }) => {
          this.uploads = data._embedded.uploads;
        })
        .then(() => {
          this.uploadedFilesDialog = true;
        });
    },
    formatSerialNumber(item) {
      if (item.serialNumber) {
        if (item.serialNumber.startsWith("S01")) {
          item.serialNumber = item.serialNumber.substring(3);
        }
        if (item.serialNumber.length < 7) {
          item.serialNumber = item.serialNumber.padStart(7, "0");
        }
      }
    },
    onClear() {
      this.participantSelectedToClaimOnBehalf = null;
    },
    backToDashboard() {
      this.$router.push({ name: "dashboard" });
    },
    onEndUserDelete(v) {
      if (v.id == this.selectedEndUser.id) {
        this.selectedEndUser = null;
      }
    },
    keyHandler(v) {
      if (v.key == " " || v.key == ",") {
        event.preventDefault();
      }
    },
    loadZeroSalesMonths() {
      this.zeroSalesItems = [];
      let month = moment();
      month.subtract(1, "months");
      for (let i = 0; i < 3; i++) {
        this.zeroSalesItems.push({
          text: month.format("MMMM YYYY"),
          value: month.format("YYYY-MM-[01]")
        });
        month.add(1, "months");
      }
    }
  }
};
</script>
<style>
.publicUploadField .v-text-field__slot {
  font-size: 0.8em;
}

.promotionProductSelect .v-label {
  font-size: 0.8em;
}
.v-menu__content {
  margin-top: 15px;
}
</style>
